<template>
  <div>
    <SelectModelPicker
      identificador="modal-origem"
      campo-codigo-exibicao="id_do_tipo"
      campo-titulo-exibicao="nome"
      campo-subtitulo-exibicao="tipo"
      v-model="colaborador"
      url="/admin/conta-corrente"
      texto-clique-para-selecionar="Clique aqui para selecionar o usuário de origem"
    />
    <hr>
    {{ colaborador }}
  </div>
</template>

<script>

export default {
  name: "TestComponent",
  components: {

  },
  data() {
    return {
      tagsSelecionadas: [],
      colaborador: null,
    }
  }
}
</script>

<style scoped>

</style>